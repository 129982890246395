import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../css/juster.css"
import "../css/animation.css"
import { Helmet } from "react-helmet"

export default () => (
  <>
    <Helmet>
      <title>JustEZ - Legal Notices</title>          
      <meta name="description" content="Legal Notices" />
      <meta name="keywords" content="JustEZ"/>
    </Helmet>
    <Header></Header>
    <div class="tp-integrations how_it_works_b tp-tutorial-banner">
      <div class="container tp-integrations-container">
        <div class="integrations-info">
          <h2>Accessibility Notice</h2>
        </div>
      </div>
    </div>

    <div class="container-main">
      <div class="container how_it_works_pg">
        <div class="quote_ctr access_notice">
          <p>
            We are committed to achieving high levels of customer satisfaction.
            As part of that commitment, we continually strive to enhance the
            accessibility and usability of our services and facilities for
            people with disabilities. In the case of our websites, we endeavor
            to achieve and maintain adherence to the relevant portions of the
            World Wide Web Consortium’s Web Content Accessibility Guidelines 2.0
            Level AA (WCAG 2.0 AA).
          </p>
          <p>
            Our accessibility efforts are ongoing. If you encounter difficulty
            in accessing any of our websites or having specific questions or
            concerns about accessibility, you may request assistance or ask
            questions by calling us by phone at 844-289-7968 or sending us an
            e-mail at{" "}
            <a href="mailto:accessibility@exzeo.com">accessibility@exzeo.com</a>
            .
          </p>
        </div>
        <div class="clr"> </div>
      </div>
    </div>

    <Footer></Footer>
  </>
)
